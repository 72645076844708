<template>
  <div>
    <v-card data-cy="modal-card">
      <v-card-text data-cy="modal-content">
        <v-row>
          <v-col cols="12" md="12" class="mt-3">
            <v-row>
              <v-col cols="12" md="5" style="padding: 0px 0px 0px 12px; display: flex; align-items: center">
                <v-text-field
                  v-model="basePn"
                  label="Base Part Number"
                  variant="underlined"
                  @keydown.stop
                  @keyup.enter="basePn.length !== 0 && handlebasePn()"
                  :loading="loadingbasePn"
                  hide-details
                ></v-text-field>
                <v-btn @click="handlebasePn" class="btn-orange ml-4" data-cy="edit-product-button" :disabled="basePn.length == 0">Find</v-btn>
                <v-btn @click="openWizardModal" class="btn-orange ml-4" data-cy="edit-product-button">PN Wizard</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5" style="padding: 4px 0px 0px 12px">
                <v-autocomplete
                  v-model="selectedCategory"
                  :items="productCategory"
                  item-title="name"
                  item-value="id"
                  label="Category"
                  variant="underlined"
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" style="padding: 4px 0px 0px 12px">
                <v-text-field v-model="description" label="Description" variant="underlined" hide-details></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-tabs v-model="tab" style="border-bottom: 2px solid; margin-top: 16px; margin-left: 12px" data-cy="tabs">
            <v-tab :key="0" :active="activeTab === 0" @click="activeTab = 0" data-cy="tab-overview">
              <i class="fa-regular fa-file-invoice"></i>
              &nbsp; Customers
            </v-tab>
            <v-tab :key="1" :active="activeTab === 1" @click="activeTab = 1" data-cy="tab-bom">
              <i class="fa-duotone fa-solid fa-pen-to-square"></i>
              &nbsp; Blueprint
            </v-tab>
            <v-tab :key="2" :active="activeTab === 2" @click="activeTab = 2" data-cy="tab-bom">
              <i class="fa-sharp-duotone fa-solid fa-code-compare"></i>
              &nbsp; Certification data (BOM)
            </v-tab>
            <v-tab :key="4" :active="activeTab === 4" @click="activeTab = 4" data-cy="tab-bom">
              <i class="fa-duotone fa-solid fa-image"></i>
              &nbsp; Drawings
            </v-tab>
            <v-tab :key="4" :active="activeTab === 5" @click="activeTab = 5" data-cy="tab-bom">
              <i class="fa-duotone fa-solid fa-money-check-dollar"></i>
              &nbsp; Pricing
            </v-tab>
          </v-tabs>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <div class="tab-content">
              <div v-if="activeTab === 0">
                <CustomersVendorsTab
                  :product="actualProduct"
                  @add-customer="addCustomer"
                  @edit-customer="editCustomer"
                  @delete-customer="deleteCustomer"
                  @add-vendor="addVendor"
                  @edit-vendor="editVendor"
                  @delete-vendor="deleteVendor"
                />
              </div>
              <div v-if="activeTab === 1">
                <BlueprintTab :basePn="basePn" :pmDescription="description" />
              </div>
              <div v-if="activeTab === 2">
                <CertificationTab :basePn="basePn" />
              </div>
              <div v-if="activeTab === 4">
                <DrawingsTab />
              </div>
              <div v-if="activeTab === 5">
                <PricingTab />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel mr-2" @click="handleCancel" data-cy="cancel-button">Cancel</v-btn>
        <v-btn class="btn-save" @click="handleSave" data-cy="save-button">Save</v-btn>
      </v-card-actions>
    </v-card>
    <Wizard :showWizardModal="showWizardModal" :product="currentProduct" @close="showWizardModal = false" />

    <!-- Customer Dialog -->
    <v-dialog v-model="showCustomerDialog" max-width="600px">
      <v-card>
        <v-card-title class="modal-title">
          <span class="text-h5">{{ customerDialogMode === 'add' ? 'Add Customer' : 'Edit Customer' }}</span>
          <v-icon small @click="cancelCustomerDialog" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-autocomplete v-model="customerForm.name" :items="customersList" item-title="name" item-value="name" label="Customer Name" variant="underlined"></v-autocomplete>
          <v-text-field v-model="customerForm.productPnFormat" label="Customer PN" variant="underlined"></v-text-field>
          <v-text-field v-model="customerForm.descriptionFormat" label="Customer Description" variant="underlined"></v-text-field>
        </v-card-text>
        <v-card-actions class="px-6 pb-4">
          <v-spacer></v-spacer>
          <v-btn class="btn-cancel" @click="cancelCustomerDialog">Cancel</v-btn>
          <v-btn class="btn-save" @click="saveCustomer">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Vendor Dialog -->
    <v-dialog v-model="showVendorDialog" max-width="600px">
      <v-card>
        <v-card-title class="modal-title">
          <span class="text-h5">{{ vendorDialogMode === 'add' ? 'Add Vendor' : 'Edit Vendor' }}</span>
          <v-icon small @click="cancelVendorDialog" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-autocomplete v-model="vendorForm.name" :items="vendorsList" item-title="name" item-value="name" label="Vendor Name" variant="underlined"></v-autocomplete>
          <v-text-field v-model="vendorForm.productPnFormat" label="Vendor PN" variant="underlined"></v-text-field>
          <v-text-field v-model="vendorForm.descriptionFormat" label="Vendor Description" variant="underlined"></v-text-field>
        </v-card-text>
        <v-card-actions class="px-6 pb-4">
          <v-spacer></v-spacer>
          <v-btn class="btn-cancel" @click="cancelVendorDialog">Cancel</v-btn>
          <v-btn class="btn-save" @click="saveVendor">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import Wizard from './ProductsWizard.vue';
  import { Product, Customer, Vendor, ProductCategory } from '@/types/pm/product';
  import productsService from '@/services/api_PM/products';
  import assemblyService from '@/services/api/assemblyService';
  import CustomersVendorsTab from './tabs/CustomersVendorsTab.vue';
  import BlueprintTab from './tabs/BlueprintTab.vue';
  import CertificationTab from './tabs/CertificationTab.vue';
  import DrawingsTab from './tabs/DrawingsTab.vue';
  import PricingTab from './tabs/PricingTab.vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useProductDetailsStore } from '@/store/PM/productDetails';
  import customersAndVendorsService from '@/services/api_PM/customersAndVendors';
  import productsCategoryService from '@/services/api_PM/productsCategory';

  export default defineComponent({
    name: 'ProductDetailsModal',
    emits: ['product-updated', 'product-created'],
    components: {
      Wizard,
      CustomersVendorsTab,
      BlueprintTab,
      CertificationTab,
      DrawingsTab,
      PricingTab,
    },

    setup() {
      const router = useRouter();
      const route = useRoute();
      const productDetailsStore = useProductDetailsStore();
      return { router, route, productDetailsStore };
    },

    async created() {
      this.resetData();
      if (this.route.params.id && this.route.params.id !== 'new') {
        try {
          const productData = await productsService.getProduct(this.route.params.id as string);
          this.currentProduct = productData;
          this.basePn = productData.basePn;
          this.description = productData.description;
          this.selectedCategory = productData.categoryId || null;
          this.productDetailsStore.setProduct(productData);
          if (productData.customers && productData.customers.length > 0) {
            this.selectedCustomer = productData.customers[0].odooId || '';
            this.updateCustomerFields();
          }
        } catch (error: any) {
          this.$error.view(error);
          this.router.push('/products');
        }
      }
      this.getProductCategory();
      this.getCustomers();
      this.getVendors();
    },

    data() {
      return {
        currentProduct: {
          id: '',
          basePn: '',
          description: '',
          customers: [],
          vendors: [],
        } as Product,
        customersHeaders: [
          { title: 'Customer', value: 'name' },
          { title: 'Customer PN', value: 'productPnFormat' },
          { title: 'Customer Description', value: 'descriptionFormat' },
          { title: 'Actions', value: 'actions', sortable: false, width: '100px' },
        ],
        vendorsHeaders: [
          { title: 'Vendor', value: 'name' },
          { title: 'Vendor PN', value: 'productPnFormat' },
          { title: 'Vendor Description', value: 'descriptionFormat' },
          { title: 'Actions', value: 'actions', sortable: false, width: '100px' },
        ],
        showWizardModal: false,
        selectedCustomer: null as string | null,
        customerPN: '',
        customerDescription: '',
        basePn: '',
        loadingbasePn: false,
        description: '',
        selectedCategory: null as string | null,
        editingCustomerIndex: -1,
        editingVendorIndex: -1,

        // Customer dialog
        showCustomerDialog: false,
        customerDialogMode: 'add', // 'add' or 'edit'
        customerForm: {
          id: '',
          odooId: '',
          name: '',
          productPnFormat: '',
          descriptionFormat: '',
        },

        // Vendor dialog
        showVendorDialog: false,
        vendorDialogMode: 'add', // 'add' or 'edit'
        vendorForm: {
          id: '',
          odooId: '',
          name: '',
          productPnFormat: '',
          descriptionFormat: '',
        },
        tab: 0,
        activeTab: 0,
        customersList: [] as any,
        vendorsList: [] as any,
        productCategory: [] as ProductCategory[],
      };
    },
    computed: {
      actualProduct(): Product {
        return this.currentProduct;
      },
      customerOptions(): Customer[] {
        return this.actualProduct.customers;
      },
      vendorOptions(): Vendor[] {
        return this.actualProduct.vendors;
      },

      hasCustomerChanges() {
        if (this.customerDialogMode === 'add') {
          return this.customerForm.name !== '' || this.customerForm.productPnFormat !== '' || this.customerForm.descriptionFormat !== '';
        }

        if (this.editingCustomerIndex === -1) return false;

        const originalCustomer = this.actualProduct.customers[this.editingCustomerIndex];
        if (!originalCustomer) return false;

        return (
          this.customerForm.name !== originalCustomer.name ||
          this.customerForm.productPnFormat !== originalCustomer.productPnFormat ||
          this.customerForm.descriptionFormat !== originalCustomer.descriptionFormat
        );
      },

      hasVendorChanges() {
        if (this.vendorDialogMode === 'add') {
          return this.vendorForm.name !== '' || this.vendorForm.productPnFormat !== '' || this.vendorForm.descriptionFormat !== '';
        }

        if (this.editingVendorIndex === -1) return false;

        const originalVendor = this.actualProduct.vendors[this.editingVendorIndex];
        if (!originalVendor) return false;

        return (
          this.vendorForm.name !== originalVendor.name ||
          this.vendorForm.productPnFormat !== originalVendor.productPnFormat ||
          this.vendorForm.descriptionFormat !== originalVendor.descriptionFormat
        );
      },
    },
    methods: {
      resetData() {
        this.basePn = this.actualProduct.basePn || '';
        this.description = this.actualProduct.description || '';
        this.selectedCustomer = null;
        this.customerPN = '';
        this.customerDescription = '';
        this.productDetailsStore.setProduct(null);
      },
      handleCancel() {
        this.router.push('/products');
      },
      handleSave() {
        if (!this.basePn || !this.description || !this.selectedCategory) {
          const missingFields = [];
          if (!this.basePn) missingFields.push('Base PN');
          if (!this.description) missingFields.push('Description');
          if (!this.selectedCategory) missingFields.push('Category');
          this.$log.showError(`Please fill in the following required field${missingFields.length > 1 ? 's' : ''}: ${missingFields.join(', ')}`);
          return;
        }

        try {
          if (!this.route.params.id || this.route.params.id === 'new') {
            this.createProduct();
          } else {
            const updatedProduct = {
              ...this.currentProduct,
              basePn: this.basePn,
              description: this.description,
              customers: this.actualProduct.customers,
              vendors: this.actualProduct.vendors,
              component: this.productDetailsStore.getProduct()?.component,
            };
            this.updateProduct(updatedProduct);
          }
        } catch (error) {
          this.$log.showError('Error saving product');
        }
      },
      close() {
        this.router.push('/products');
      },
      openWizardModal() {
        this.showWizardModal = true;
      },
      formatDate(date: string) {
        return new Date(date).toLocaleDateString();
      },
      updateCustomerFields() {
        if (!this.actualProduct || !this.selectedCustomer) return;

        const customer = this.actualProduct.customers.find((c: any) => c.odooId === this.selectedCustomer);
        if (customer) {
          this.customerPN = customer.productPnFormat.replace(/LENGTH\(\d+\)\+?UOM/g, '100M');
          this.customerDescription = customer.descriptionFormat.replace(/LENGTH/g, '100 Meters');
        }
      },

      parsebasePn(basePn: string): string {
        const regex = /-(M|F|CM|I)(\d+)$/;
        return basePn.replace(regex, '');
      },

      handlebasePn() {
        if (!this.basePn) {
          this.$log.showError('Please enter a Base Part Number');
          return;
        }

        this.basePn = this.parsebasePn(this.basePn);
        this.getbasePnData(this.basePn);
      },

      async getbasePnData(item: any) {
        this.loadingbasePn = true;
        try {
          const response = await assemblyService.generateAssembly(item);
          if (response) {
            this.description = response.description;
          }
          this.loadingbasePn = false;
        } catch (error: any) {
          this.loadingbasePn = false;
          this.$error.view(error);
        }
      },
      async getProductCategory() {
        try {
          this.productCategory = await productsCategoryService.getProductsCategoryList();
          this.productCategory.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
        } catch (error) {
          this.$log.showError('Error getting Product Category');
        }
      },
      async getCustomers() {
        try {
          const response = await customersAndVendorsService.getCustomers();
          this.customersList = response;
          console.log('customersList', this.customersList);
        } catch (error) {
          this.$log.showError('Error getting Customers');
        }
      },
      async getVendors() {
        try {
          const response = await customersAndVendorsService.getVendors();
          this.vendorsList = response;
          console.log('vendorsList', this.vendorsList);
        } catch (error) {
          this.$log.showError('Error getting Vendors');
        }
      },

      async createProduct() {
        const newProduct = {
          basePn: this.basePn,
          description: this.description,
          categoryId: this.selectedCategory || null,
          customers: this.actualProduct.customers,
          vendors: this.actualProduct.vendors,
          component: this.productDetailsStore.getProduct()?.component,
        };

        try {
          const response = await productsService.createProduct(newProduct);
          if (response) {
            this.$emit('product-created');
            this.$log.showSuccessMessage('Product created successfully');
            this.close();
          } else {
            this.$log.showError('Error creating product');
          }
        } catch (error) {
          this.$log.showError('Error creating product');
        }
      },

      async updateProduct(updatedProduct: Product) {
        try {
          const productToUpdate = {
            ...updatedProduct,
            categoryId: this.selectedCategory || null,
          };

          console.log('Sending updated product to API:', JSON.stringify(productToUpdate));

          const response = await productsService.updateProduct(productToUpdate.id, productToUpdate);
          if (response) {
            this.$emit('product-updated');
            this.$log.showSuccessMessage('Product updated successfully');
            this.close();
          } else {
            this.$log.showError('Error updating product');
          }
        } catch (error) {
          console.error('Error updating product:', error);
          this.$log.showError('Error updating product');
        }
      },

      // Customer Methods
      addCustomer() {
        this.customerDialogMode = 'add';
        this.resetCustomerForm();
        this.showCustomerDialog = true;
      },

      editCustomer(customer: Customer) {
        this.customerDialogMode = 'edit';
        this.customerForm = {
          id: customer.id || '',
          odooId: customer.odooId || '',
          name: customer.name || '',
          productPnFormat: customer.productPnFormat || '',
          descriptionFormat: customer.descriptionFormat || '',
        };
        this.editingCustomerIndex = this.actualProduct.customers.findIndex((c) => c.id === customer.id);
        this.showCustomerDialog = true;
      },

      deleteCustomer(customer: Customer) {
        const index = this.currentProduct.customers.findIndex((c) => c.id === customer.id);
        if (index !== -1) {
          const updatedCustomers = [...this.currentProduct.customers];
          updatedCustomers.splice(index, 1);
          this.currentProduct.customers = updatedCustomers;
        }
      },

      resetCustomerForm() {
        this.customerForm = {
          id: '',
          odooId: '',
          name: '',
          productPnFormat: '',
          descriptionFormat: '',
        };
      },

      cancelCustomerDialog() {
        this.showCustomerDialog = false;
        this.resetCustomerForm();
      },

      saveCustomer() {
        if (!this.customerForm.name || !this.customerForm.productPnFormat) {
          this.$log.showError('Please fill in all required customer fields');
          return;
        }

        try {
          if (this.customerDialogMode === 'add') {
            const selectedCustomer = this.customersList.find((c: any) => c.name === this.customerForm.name);
            if (!selectedCustomer) {
              this.$log.showError('Selected customer not found in the customers list');
              return;
            }

            const newCustomer = {
              name: this.customerForm.name,
              odooId: selectedCustomer.id,
              productPnFormat: this.customerForm.productPnFormat,
              descriptionFormat: this.customerForm.descriptionFormat,
            };

            this.currentProduct.customers = [...this.currentProduct.customers, newCustomer];
          } else {
            const updatedCustomer = {
              name: this.customerForm.name,
              odooId: this.customerForm.odooId,
              productPnFormat: this.customerForm.productPnFormat,
              descriptionFormat: this.customerForm.descriptionFormat,
            };

            const index = this.editingCustomerIndex;
            if (index !== -1) {
              const updatedCustomers = [...this.actualProduct.customers];
              updatedCustomers[index] = updatedCustomer;
              this.currentProduct.customers = updatedCustomers;
            } else {
              this.$log.showError('Error updating customer: Customer not found');
              return;
            }
          }

          this.showCustomerDialog = false;
          this.resetCustomerForm();
          this.editingCustomerIndex = -1;
        } catch (error) {
          this.$log.showError('Error saving customer');
        }
      },

      // Vendor Methods
      addVendor() {
        this.vendorDialogMode = 'add';
        this.resetVendorForm();
        this.showVendorDialog = true;
      },

      editVendor(vendor: Vendor) {
        this.vendorDialogMode = 'edit';
        this.vendorForm = {
          id: vendor.id || '',
          odooId: vendor.odooId || '',
          name: vendor.name || '',
          productPnFormat: vendor.productPnFormat || '',
          descriptionFormat: vendor.descriptionFormat || '',
        };
        this.editingVendorIndex = this.actualProduct.vendors.findIndex((v) => v.id === vendor.id);
        this.showVendorDialog = true;
      },

      deleteVendor(vendor: Vendor) {
        const index = this.currentProduct.vendors.findIndex((v) => v.id === vendor.id);
        if (index !== -1) {
          const updatedVendors = [...this.currentProduct.vendors];
          updatedVendors.splice(index, 1);
          this.currentProduct.vendors = updatedVendors;
        }
      },

      resetVendorForm() {
        this.vendorForm = {
          id: '',
          odooId: '',
          name: '',
          productPnFormat: '',
          descriptionFormat: '',
        };
      },

      cancelVendorDialog() {
        this.showVendorDialog = false;
        this.resetVendorForm();
      },

      saveVendor() {
        if (!this.vendorForm.name || !this.vendorForm.productPnFormat) {
          this.$log.showError('Please fill in all required vendor fields');
          return;
        }

        try {
          if (this.vendorDialogMode === 'add') {
            const selectedVendor = this.vendorsList.find((v: any) => v.name === this.vendorForm.name);
            if (!selectedVendor) {
              this.$log.showError('Selected vendor not found in the vendors list');
              return;
            }

            const newVendor = {
              name: this.vendorForm.name,
              odooId: selectedVendor.id,
              productPnFormat: this.vendorForm.productPnFormat,
              descriptionFormat: this.vendorForm.descriptionFormat,
            };

            this.currentProduct.vendors = [...this.currentProduct.vendors, newVendor];
          } else {
            const updatedVendor = {
              name: this.vendorForm.name,
              odooId: this.vendorForm.odooId,
              productPnFormat: this.vendorForm.productPnFormat,
              descriptionFormat: this.vendorForm.descriptionFormat,
            };

            const index = this.editingVendorIndex;
            if (index !== -1) {
              const updatedVendors = [...this.actualProduct.vendors];
              updatedVendors[index] = updatedVendor;
              this.currentProduct.vendors = updatedVendors;
            } else {
              this.$log.showError('Error updating vendor: Vendor not found');
              return;
            }
          }

          this.showVendorDialog = false;
          this.resetVendorForm();
          this.editingVendorIndex = -1;
        } catch (error) {
          this.$log.showError('Error saving vendor');
        }
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
  }

  .class-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .v-data-table.mb-6 {
    margin-bottom: 1.5rem;
  }
  .controls-wrapper {
    padding: 0 8px;
    gap: 16px;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
  }

  .fa-pen-to-square {
    cursor: pointer;
  }
  .fa-pen-to-square:hover {
    color: #f0ad4e;
  }
  .fa-trash {
    color: #d9534f;
    cursor: pointer;
  }
  .fa-trash:hover {
    color: red;
  }

  ::v-deep(.v-data-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table tr:hover) {
    background-color: #f7f7f7;
  }

  .tab-content {
    margin-top: -12px;
    background-color: #f5f5f5;
    border-radius: 0 0 10px 10px;
    padding: 16px;
  }
</style>
